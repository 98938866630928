@import "../../variables";

header {
  min-height: $header-height;
  width: 100vw;
  margin-block: 1rem;
  text-align: center;
  div#wrapper-admin-login {
    padding-block: 1em;
    padding-inline: 3em;
    max-width: 100%;
    border-bottom: $section-border-bottom;
  }

  & a,
  a:hover,
  a:visited,
  a:active {
    color: inherit;
    text-decoration: underline;
  }
}

@media screen and (max-width: $width-max-mobile) {
  header div#wrapper-admin-login {
    padding-inline: 1em;

    form div.row label {
      text-align: start;
    }
  }
}