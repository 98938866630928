@import "../../variables";

div.article-full {
  padding: 5px;

  .article-title-wrapper {
    margin-bottom: 1rem;
    margin-left: .5rem;
    h6 {
      margin-left: 1em;
    }
  }

  .article-cover-image {
    display: flex;
    justify-content: center;
    & img {
      width: $article-cover-image-size;
    }
  }

  h6 {
    &.date {
      font-size: small;
      color: grey;
    }
  }
}
