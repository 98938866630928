@import "../../variables";

div.about-me {
  padding-bottom: $section-padding-bottom;
  min-height: $about-me-image-max-height;

  h2 {
    margin-bottom: 1rem;
    margin-left: .5rem;
  }

  div.image-container {
    float: left;
    margin-right: 1em;

    div.image-wrapper {
      max-width: 100%;
      overflow-x: scroll;
      img {
        max-width: $about-me-image-max-width;
        max-height: $about-me-image-max-height;
      }
    }
  }
}

@media screen and (max-width: $width-max-mobile) {
  div.about-me {
    div.ql-editor {
      text-align: center;
    }
    div.image-container {
      float: none;
      margin-right: 0 ;
      margin-bottom: 1em;

      div.image-wrapper {
        display: block;
        margin: 0 auto;
      }
    }
  }
}
