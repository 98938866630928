@import "../../../variables";

div.brand {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  border-bottom: $section-border-bottom;

  h1 {
    a {
      text-decoration: none;
    }
  }
  button {
    &#admin-logout {
        position: absolute;
        right: .5em;
        margin-bottom: 1rem;
      }
    &#admin-dashboard {
        position: absolute;
        left: .5em;
        margin-bottom: 1rem;
      }
  }
}
