@import "../../variables";

.no-articles {
  text-align: center;
}

div.category-page {
  h2 {
    margin: 0;
    margin-left: 10px;
  }
}

@media screen and (max-width: $width-max-mobile) {
  div.category-page {
    // background-color: red;
    a.article-teaser.md {
      @include handleArticleTeaserSize(
        $width: $article-teaser-width-lg,
        $height: $article-teaser-height-lg,
        $margin: $article-teaser-margin-lg
      );
    }
  }
}
