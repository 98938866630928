@import "../../variables";
div.image-editor {
  margin-block: 1em;
  padding-block: 1em;
  border-block: $section-border-bottom;
}

div.crop-container {
  position: relative;
  min-height: 300px;
  width: 100%;
  margin: 0 auto;
}
