
.ql-editor {
    p {
      font-size: 16px;
  
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }