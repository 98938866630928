@import "../../variables";

div.image-container {
  div.image-wrapper {
    width: fit-content;

    position: relative;
    -webkit-box-shadow: $box-shadow-inset;
    -moz-box-shadow: $box-shadow-inset;
    box-shadow: $box-shadow-inset;
    overflow: hidden;

    border: solid $theme-color-0 $shadow-spread-radius;
    border-radius: $border-radius;

    &.image-round {
      border-radius: 50%;
    }
    img {
      &.image-round {
        border-radius: 50%;
      }
      position: relative;
      max-width: 100%;
      z-index: -10;
    }
  }
}
