@import "../../variables";

a.article-teaser {
  width: fit-content;
  display: flex;
  flex-direction: column;

  &.lg {
    @include handleArticleTeaserSize(
      $width: $article-teaser-width-lg,
      $height: $article-teaser-height-lg,
      $margin: $article-teaser-margin-lg
    );
  }
  &.md {
    @include handleArticleTeaserSize(
      $width: $article-teaser-width-md,
      $height: $article-teaser-height-md,
      $margin: $article-teaser-margin-md
    );
  }
  &.sm {
    min-height: $article-teaser-height-sm;
    @include handleArticleTeaserSize(
      $width: $article-teaser-width-sm,
      $height: $article-teaser-height-sm,
      $margin: $article-teaser-margin-sm
    );
  }

  > div.status-wrapper {
    display: block;
    text-align: end;
    p {
      display: inline;
      margin: 0 0 0 0;
    }
  }

  > div.teaser-image-wrapper {
    margin-bottom: 1rem;
    img {
      max-width: 100%;
    }
  }

  > div.title-wrapper {
    text-align: center;
    h3 {
      display: inline;
    }
  }
}
