@import "../../variables";

section.admin-page {
  section {
    .admin-section-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      padding-top: 0.5em;
      div {
        display: flex;
        align-items: center;
        h2 {
          margin: 0;
          margin-right: .5em;
        }
      }
    }
    .admin-section-teasers {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 3em;
    }
  }
  .admin-section-new-category {
    margin-bottom: 3em;
  }
}

@media screen and (max-width: $width-max-mobile) {
  section.admin-page {
    // background-color: red;
    a.article-teaser.md {
      @include handleArticleTeaserSize(
        $width: $article-teaser-width-lg,
        $height: $article-teaser-height-lg,
        $margin: $article-teaser-margin-lg
      );
    }
  }
}
