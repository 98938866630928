@import "../../variables";
/*
    scroll snap
    https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-snap-type#examples
    https://www.amitmerchant.com/implementing-scroll-snap-using-only-three-lines-of-css/
*/

$snap-scroll-teaser-list-height: 100%;
$snap-scroll-teaser-list-width: 512px;
$snap-scroll-teaser-list-max-width: 80vw;

.snap-scroll-teaser-list {

  height: $snap-scroll-teaser-list-height;
  border: solid 1px $theme-color-1;
  border-radius: $border-radius;
  margin: 0 auto;
  width: 100%;

  overflow-x: hidden;

  & > div {
    height: 100%;
    max-width: 100%;
    margin: 0 auto;

    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    @include disableScrollBar();

    & > div {
      scroll-snap-align: start;
      > a.article-teaser {
        margin-block: 1em;
        margin-left: 1em;
      }
      &:last-child a.article-teaser {
        margin-right: 1em;
      }
    }
  }
}

@media screen and (max-width: $width-max-mobile) {
  .snap-scroll-teaser-list {
    & > div {
      max-width: 100vw;
    }
  }
}
