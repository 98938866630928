@import "../../variables";

div.page-not-found {
  padding-top: 5em;
  text-align: center;

  h1 {
    font-size: 3em;
    margin-block: 0;
  }

  div {
    margin-top: 5em;
    a:-webkit-any-link {
      text-decoration: underline;
    }
  }
}
