@import "../../variables";

footer {
  // min-height: $footer-height;
  max-width: 100vw;
  padding-inline: 1em;
  padding-block: 2em;

  border-top: $section-border-bottom;
  bottom: 0;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  a:-webkit-any-link.copyright {
    color: rgba(0, 0, 0, 0.38);
  }
  a img {
    border-radius: $border-radius-app-icon;
    margin-left: 1em;
  }
}
