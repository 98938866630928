@import "../../../variables";

nav {
  border-top: $section-border-bottom;
  margin-bottom: 1rem;
  
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 0;

    li {
      list-style: none;
      display: inline;
      margin-inline: 1rem;
      & > a.underline {
        text-decoration: underline !important;
        color: $theme-color-0;
      }
    }
  }
}
