/*
  THEME
*/
$theme-color-0: #b49543;
$theme-color-1:rgba(0, 0, 0, 0.144);
$theme-color-editor-line-color:rgba(68, 157, 217, 0.5);

$header-height: 80px;
$nav-height: 50px;
$footer-height: 60px;
$min-page-height: calc(100vh - ($header-height + $nav-height + $footer-height));

$border-radius-app-icon: 1em;
$border-radius: 5px;
$border-width-primary: 10px;

/*
  ABOUT ME SECTION
*/
$about-me-image-max-width: 300px;
$about-me-image-max-height: $about-me-image-max-width;

/*
  EDITOR
*/
$editor-line-color: $theme-color-editor-line-color;
$editor-line-width: 2px;

/*
  SECTIONS
*/
$section-border-bottom: solid 2px $theme-color-1;
$section-padding-bottom: 4em;

/*
  ARTICLE COVER
*/
$article-cover-image-size: 500px;

/*
  ARTICLE TEASERS
*/
$article-teaser-initial-margin: 30px;
$article-teaser-initial-width: 400px;

$article-teaser-margin-lg: $article-teaser-initial-margin;
$article-teaser-width-lg: $article-teaser-initial-width;
$article-teaser-height-lg: calc($article-teaser-width-lg + 250px);

$article-teaser-margin-md: calc($article-teaser-initial-margin - 10px);
$article-teaser-width-md: calc($article-teaser-width-lg * 0.5);
$article-teaser-height-md: calc($article-teaser-height-lg * 0.5);

$article-teaser-margin-sm: calc($article-teaser-initial-margin + 20px);
$article-teaser-width-sm: calc($article-teaser-width-lg * 0.35);
$article-teaser-height-sm: calc($article-teaser-height-lg * 0.35);

@mixin handleArticleTeaserSize($width, $height, $margin) {
  min-width: $width;
  max-width: $width;
  min-height: $height;

  // margin-bottom: $margin;
  // margin: 1em;

  $calc-width: calc($width * 0.9);

  > div.image-wrapper {
    max-height: $calc-width;
    max-width: $width;
    img {
      width: $calc-width;
    }
  }
}

/*
    BOX SHADOW
    https://designshack.net/articles/css/inner-shadows-in-css-images-text-and-beyond/
*/
$shadow-spread-radius: 5px;
$box-shadow: 0 // horizontal offset
  0 // vertical offset
  10px // blur radius
  $shadow-spread-radius // spread radius
  rgba(0, 0, 0, 0.3); // shadow color

$box-shadow-inset: inset $box-shadow;

/*
  BREAKPOINTS
  breakpoints ot match bootstrap behaviors
*/
$width-max-mobile: 576px;
$width-min-tablet: 577px;

$width-max-tablet: 768px;
$width-min-laptop: 769px;

$width-max-laptop: 992px;

/*
  HIDE SCROLLBAR
*/
@mixin disableScrollBar() {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
