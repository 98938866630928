@import "../../variables";

div.editor-page {
  margin-inline: 0.5em;
}

.ql-container .ql-editor {
  > * {
    border-bottom: solid $editor-line-color $editor-line-width;
  }
}
