@import "../../variables";

div.article-recent-list {
  padding-bottom: $section-padding-bottom;

  h2 {
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }

  div.teaser-list-wrapper {
    display: flex;
    overflow-x: scroll;

    @include disableScrollBar();

    & .error {
      width: 100%;
      text-align: center;
    }

    & a.article-teaser {
      &.sm {
        // border: solid 1px deeppink;
      }
    }
  }
}

@media screen and (max-width: $width-max-mobile) {
  div.article-recent-list {

    div.a.article-teaser {
      &.md {
        &:brandText-child {
          margin-left: 0;
        }
        background-color: green;
      }
    }
  }
}
