@import "./variables";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

html {
  body div#root {
    min-height: 100vh;
    max-width: 100vw;
    position: relative;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    line-height: 1.6;
    font-weight: 400;
    scroll-behavior: smooth;

    a:-webkit-any-link {
      color: inherit;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

div.page-wrapper {
  min-height: $min-page-height;
  margin: 0 auto;
  padding-inline: 1em;

  & .loading {
    display: block;
    text-align: center;
  }
}

.ql-editor {
  width: 100% !important;
  padding-top: 0 !important;
}

@media screen and (min-width: $width-min-laptop) {
  div.page-wrapper {
    width: 70vw;
  }
}
